@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.App {
  text-align: center;
  background-color: whitesmoke;
  height: 100%;
}

.resp-iframe-container {
  position: relative;
  padding-top: 20%;
  min-height: 600px;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#barcode {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

/* Custom Fonts */

/* rosan */

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 10pt;
}

#routesContainer {
  overflow: hidden;
  min-height: 600px;
  height: 100%;
  width: 100%;
}

/* #app-routes[style*='background'] > #routesContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#app-routes[style*='background'] {
  max-width: 800px;
  margin: auto;
  width: 100%;
  height: 100%;
} */

