/* Custom Fonts */

/* rosan */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 10pt;
}

#routesContainer {
  overflow: hidden;
  min-height: 600px;
  height: 100%;
  width: 100%;
}

/* #app-routes[style*='background'] > #routesContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#app-routes[style*='background'] {
  max-width: 800px;
  margin: auto;
  width: 100%;
  height: 100%;
} */
